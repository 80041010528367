import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from "react-input-mask";

import { Modal } from "react-bootstrap";

const Fatores = () => {
  const { addToast } = useToasts();
  const [ regional, setRegionals ] = useState([]);
  const [ estadoSelect, setEstadoSelect ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaIBGE, setBuscaIBGE ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaUF, setBuscaUF ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formFatores, setFormFatores ] = useState({});
  const [ caCep, setCaCep ] = useState(false);
  const [ acao, setAcao ] = useState();

   useEffect(() => {
    getListar();
    //getEstadoSelect();
  }, []);

  function getListar() { 
    setCaProcessa(true);
    let url1 = window.location.href;
    //console.log(window.location.href);
    let urlSplit = url1.split("/"); // cria um array separados por '&'
    //let url = `${urlSplit[5]}&${urlSplit[6]}`;
    var data = {
      usuario: `${urlSplit[5]}`,
      hash: `${urlSplit[6]}`,
    }
    //console.log(url);
    Api.post("fatores/novo", data).then(rps => {
      
      setFormFatores(rps.data.obj);
      setCaProcessa(false);
    })
  }

  function salvar(){
    setCaProcessa(true);
    Api.post('fatores/salvar', formFatores).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormFatores({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

     
    return (
      <>
        <div>

        <center><img alt="Logo" src="https://meddiagnostico.com.br/motores/motorClinica/assets/img/logo.png" className="logo-sticky mb-15" style={{width: '200px', textAlign: 'center', margin: 'auto'}} /></center>
                          

        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2 mt-5">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Triagem de Fatores de Risco</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              
              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 

                <div className="row mt-5 ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <label>Nome completo do paciente <span class="text-danger">*</span></label>
                    <input type="text" value={formFatores?.nome} onChange={e => {setFormFatores({...formFatores, nome: e.target.value})}} className="form-control" disabled />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <label>Nome social <span class="text-danger">*</span></label>
                    <input type="text" value={formFatores?.nome_social} onChange={e => {setFormFatores({...formFatores, nome_social: e.target.value})}} className="form-control" disabled />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Qual o seu peso atual? <span class="text-danger">*</span></label>
                    <NumericFormat value={formFatores?.peso} 
                                   onChange={e => {setFormFatores({...formFatores, peso: e.target.value})}} 
                                   decimalSeparator="," 
                                   className="form-control"
                                   maxLength={6}
                                   decimalScale={2} 
                                   placeholder='Ex.: 123,45'/>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Qual a sua altura? <span class="text-danger">*</span></label>
                    <NumericFormat value={formFatores?.altura} 
                                   onChange={e => {setFormFatores({...formFatores, altura: e.target.value})}} 
                                   className="form-control"
                                   decimalSeparator="," 
                                   maxLength={4}
                                   decimalScale={2} 
                                   placeholder='Ex.: 1,23' />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <h3>1. Condições clínicas</h3>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Alguma necessidade especial que devemos considerar durante seu atendimento?</label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.necessidade === true ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, necessidade:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.necessidade === false ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, necessidade:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label> Em caso afirmativo a pergunta anterior, descreva</label>
                    <input type="text" placeholder="Digite a necessidade especial" 
                      onChange={e => {setFormFatores({...formFatores, necessidade_especial: e.target.value})}} className="form-control" 
                      disabled={formFatores?.necessidade === false ? true : false} />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Você tem histórico de claustrofobia ou desconforto em ambiente fechados? <span class="text-danger">*</span> </label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.claustrofobia === true ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, claustrofobia:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.claustrofobia === false ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, claustrofobia:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Você tem alguma condição médica, como Parkinson, que possa afetar seus movimentos durante o exame? <span class="text-danger">*</span></label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.condicoes === true ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, condicoes:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.condicoes === false ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, condicoes:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <h3>2. Apenas pacientes do sexo feminino</h3>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Esta gestante?</label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.gestante === 0 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, gestante:0})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.gestante === 1 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, gestante:1})}}/>
                      <span></span>Não</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.gestante === 2 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, gestante:2})}} />
                      <span></span>Não Sei</label>
                    </div>
                    <label className='text-danger'>Não descrever com certeza esta informação poderá implicar na realização do exame.</label>
                  </div>

                  <div className="form-group col-md-3">
                    <label>Se estiver grávida, pode fornecer informações sobre quantas semanas de gestação? </label>
                    <input type="text" value={formFatores?.gestante_semana} 
                      onChange={e => {setFormFatores({...formFatores, gestante_semana: e.target.value})}} className="form-control" 
                      disabled={formFatores?.gestante === 1 || formFatores?.gestante === 2 ? true : false}/>
                  </div>

                  <div className="form-group col-md-3">
                    <label>Data da última menstruação? </label>
                    <div className="input-group">
                      <DatePicker
                        name="dt_final"
                        onChange={e => { setFormFatores({ ...formFatores, ultima_mestruacao: e }); }}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa"
                        dateFormat="dd/MM/y"
                        selectsEnd
                        disabled={formFatores?.gestante === 1 || formFatores?.gestante === 2 ? true : false}
                        selected={formFatores.ultima_mestruacao}
                        customInput={
                          <InputMask mask="99/99/9999" />} />
                      </div>
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <h3>3. Fatores de risco para reações adversas NÃO renais</h3>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Você já fez exames de imagem usando algum tipo de contraste? (pode assinalar mais de uma alternativa)</label>
                    <div class="radio-list">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.tipo_contraste === 0 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, tipo_contraste:0})}} />
                      <span></span>Iodado (usado em exames de tomografia, raios-x com contraste, angiografia)</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.tipo_contraste === 1 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, tipo_contraste:1})}} />
                      <span></span>Gadolínio (usado em exames de ressonância magnética)</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.tipo_contraste === 2 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, tipo_contraste:2})}} />
                      <span></span>Não recordo</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Foi prescrito pré-medicação devido a alguma condição de saúde quando você fez o exame de imagem com contraste? Em caso afirmativo, qual tipo de contraste foi utilizado?</label>
                    <div class="radio-list">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.pre_medicacao === 0 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, pre_medicacao:0})}} />
                      <span></span>Iodado</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.pre_medicacao === 1 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, pre_medicacao:1})}} />
                      <span></span>Gadolínio</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.pre_medicacao === 2 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, pre_medicacao:2})}} />
                      <span></span>Não recordo</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.pre_medicacao === 3 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, pre_medicacao:3})}} />
                      <span></span>Não utilizei pré-medicação</label>
                    </div>
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Ao ser submetido ao uso de contraste teve reação? <span class="text-danger">*</span></label>
                    <div class="radio-list">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.reacao_contraste === 0 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, reacao_contraste:0})}} />
                      <span></span>Sim, contraste iodado</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.reacao_contraste === 1 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, reacao_contraste:1})}} />
                      <span></span>Sim, contraste gadolínio</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.reacao_contraste === 2 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, reacao_contraste:2})}} />
                      <span></span>Não recordo</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.reacao_contraste === 3 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, reacao_contraste:3})}} />
                      <span></span>Não apresentei reação ao usar o contraste</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Em caso afirmativo de reação, descreva:</label>
                    <input type="text" value={formFatores?.contrate_reacao} 
                           onChange={e => {setFormFatores({...formFatores, descricao_reacao_contraste: e.target.value})}}
                           placeholder='Descreva a reação' 
                           disabled={formFatores?.reacao_contraste === 2 || formFatores?.reacao_contraste === 3 ? true : false} className="form-control" />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Você possui asma? <span class="text-danger">*</span></label>
                    <div class="radio-list">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.asma === 0 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, asma:0})}} />
                      <span></span>Sim. Na pergunta seguinte, por favor compartilhe informações sobre a data da ultima crise.</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.asma === 1 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, asma:1})}} />
                      <span></span>Não</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.asma === 2 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, asma:2})}} />
                      <span></span>Não recordo</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>A partilha da informação sobre sua crise asmática, nos possibilitará definir o protocolo apropriado para seu exame.</label>
                    <input type="text" value={formFatores?.asma_crise} 
                      onChange={e => {setFormFatores({...formFatores, asma_crise: e.target.value})}} className="form-control" 
                      disabled={formFatores?.asma === 1 || formFatores?.asma === 2 ? true : false} />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <h3>4. Fatores de risco para reações adversas renais</h3>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <label>Os fatores de risco para reações adversas renais são condições ou comportamentos que aumentam a necessidade de rastreabilidade da função renal antes do uso do contraste através do exame de creatinina.</label>
                    <div class="row">
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th></th>
                            <th className='text-center'>Sim</th>
                            <th className='text-center'>Não</th>
                            <th className='text-center'>Não recordo</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Problemas renais?</th>
                            <td className='text-center'><input type="radio" checked={formFatores?.problemas_renais === 0 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, problemas_renais:0})}} /></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.problemas_renais === 1 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, problemas_renais:1})}} /></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.problemas_renais === 2 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, problemas_renais:2})}} /></td>
                          </tr>
                          <tr>
                            <th>Proteína na urina?</th>
                            <td className='text-center'><input type="radio" checked={formFatores?.proteina_urina === 0 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, proteina_urina:0})}} /></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.proteina_urina === 1 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, proteina_urina:1})}}/></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.proteina_urina === 2 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, proteina_urina:2})}} /></td>
                          </tr>
                          <tr>
                            <th>Diabetes?</th>
                            <td className='text-center'><input type="radio" checked={formFatores?.diabetes === 0 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, diabetes:0})}}  /></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.diabetes === 1 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, diabetes:1})}}  /></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.diabetes === 2 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, diabetes:2})}}  /></td>
                          </tr>
                          <tr>
                            <th>Cirurgia renal?</th>
                            <td className='text-center'><input type="radio" checked={formFatores?.cirurgia_renal === 0 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, cirurgia_renal:0})}}/></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.cirurgia_renal === 1 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, cirurgia_renal:1})}} /></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.cirurgia_renal === 2 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, cirurgia_renal:2})}} /></td>
                          </tr>
                          <tr>
                            <th>Ablação renal?</th>
                            <td className='text-center'><input type="radio" checked={formFatores?.ablacao_renal === 0 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, ablacao_renal:0})}} /></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.ablacao_renal === 1 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, ablacao_renal:1})}} /></td>
                            <td className='text-center'><input type="radio" checked={formFatores?.ablacao_renal === 2 ? true : false} 
                               onChange={e=>{ setFormFatores({...formFatores, ablacao_renal:2})}} /></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>

                <div className='row ml-5 mr-5'>
                  <div className="form-group col-md-6">
                    <label>Você possui insuficiência renal crônica e  atualmente esta em tratamento de diálise renal? <span class="text-danger">*</span></label>
                    <div class="radio-list">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.insuficiencia === 0 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, insuficiencia:0})}} />
                      <span></span>Sim. Na pergunta seguinte, por favor compartilhe informações sobre os dias e horários da diálise.</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.insuficiencia === 1 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, insuficiencia:1})}} />
                      <span></span>Sim. Porém não realizo diálise.</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.insuficiencia === 2 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, insuficiencia:2})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>A partilha da informação sobre dos dias da semana e horários da diálise, nos possibilitará definir o protocolo apropriado para seu exame.</label>
                    <input type="text" value={formFatores?.dialise} 
                      onChange={e => {setFormFatores({...formFatores, dialise: e.target.value})}} className="form-control" 
                      disabled={formFatores?.insuficiencia === 1 || formFatores?.insuficiencia === 2 ? true : false}/>
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Atualmente em tratamento de diabetes, obesidade e/ou síndrome do ovário policístico? <span class="text-danger">*</span></label>
                    <div class="radio-list">
                      <label class="radio">
                      <input type="radio" checked={formFatores?.tratamento === 0 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, tratamento:0})}} />
                      <span></span>Sim. Na pergunta seguinte, por favor compartilhe o nome da medicação.</label>
                      <label class="radio">
                      <input type="radio" checked={formFatores?.tratamento === 1 ? true : false} 
                        onChange={e=>{ setFormFatores({...formFatores, tratamento:1})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Em caso afirmativo pra pergunta anterior, descreva o nome das medicações.</label>
                    <input type="text" value={formFatores?.medicacao} 
                      onChange={e => {setFormFatores({...formFatores, medicacao: e.target.value})}} className="form-control" 
                      disabled={formFatores?.tratamento === 1 ? true : false}/>
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Algum comentário adicional que ache pertinente?</label>
                    <input type="text" value={formFatores?.comentario} onChange={e => {setFormFatores({...formFatores, comentario: e.target.value})}} className="form-control" />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Algum feedback geral?</label>
                    <input type="text" value={formFatores?.feedback} onChange={e => {setFormFatores({...formFatores, feedback: e.target.value})}} className="form-control" />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <label>Responsável pela realização do questionário (Nome completo e grau de parentesco)</label>
                    <input type="text" value={formFatores?.resposavel} onChange={e => {setFormFatores({...formFatores, responsavel: e.target.value})}} className="form-control" />
                  </div>
                </div>

                <div className='row ml-5 mr-5'>
                  <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold btn-block mb-5"><i className="fas fa-save" />Salvar</button>
                </div>
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>       
                 
        </>
    );
}

export default withRouter(Fatores);                  