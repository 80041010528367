import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from "react-input-mask";

import { Modal } from "react-bootstrap";

const Seguranca = () => {
  const { addToast } = useToasts();
  const [ regional, setRegionals ] = useState([]);
  const [ estadoSelect, setEstadoSelect ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaIBGE, setBuscaIBGE ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaUF, setBuscaUF ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formSeguranca, setFormSeguranca ] = useState({});
  const [ caCep, setCaCep ] = useState(false);
  const [ acao, setAcao ] = useState();

   useEffect(() => {
    getListar();
    //getEstadoSelect();
  }, []);

  function getListar() { 
    setCaProcessa(true);
    let url1 = window.location.href;
    let urlSplit = url1.split("/");
    var data = {
      usuario: `${urlSplit[5]}`,
      hash: `${urlSplit[6]}`,
    } 
    Api.post("seguranca/novo", data).then(rps => {
      
      setFormSeguranca(rps.data.obj);
      setCaProcessa(false);
    })
  }

  function salvar(){
    setCaProcessa(true);
    Api.post('seguranca/salvar', formSeguranca).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormSeguranca({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

     
    return (
      <>
        <div>

        <center><img alt="Logo" src="https://meddiagnostico.com.br/motores/motorClinica/assets/img/logo.png" className="logo-sticky mb-15" style={{width: '200px', textAlign: 'center', margin: 'auto'}} /></center>
                          

        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2 mt-5">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Triagem de Segurança</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              
              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 

                <div className="row mt-5 ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <label>Nome completo do paciente <span class="text-danger">*</span></label>
                    <input type="text" value={formSeguranca?.nome} onChange={e => {setFormSeguranca({...formSeguranca, nome: e.target.value})}} className="form-control" disabled />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <label>Nome social <span class="text-danger">*</span></label>
                    <input type="text" value={formSeguranca?.nome_social} onChange={e => {setFormSeguranca({...formSeguranca, nome_social: e.target.value})}} className="form-control" disabled />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Qual o seu peso atual? <span class="text-danger">*</span></label>
                    <NumericFormat value={formSeguranca?.peso} 
                                   onChange={e => {setFormSeguranca({...formSeguranca, peso: e.target.value})}} 
                                   decimalSeparator="," 
                                   className="form-control"
                                   maxLength={6}
                                   decimalScale={2} 
                                   placeholder='Ex.: 123,45'/>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Qual a sua altura? <span class="text-danger">*</span></label>
                    <NumericFormat value={formSeguranca?.altura} 
                                   onChange={e => {setFormSeguranca({...formSeguranca, altura: e.target.value})}} 
                                   className="form-control"
                                   decimalSeparator="," 
                                   maxLength={4}
                                   decimalScale={2} 
                                   placeholder='Ex.: 1,23' />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Você possui algum dispositivo cardíaco ou neuro estimuladores (dispositivo usado  para tratar condições crônicas de  dor, tremores ou distúrbios neurológicos)? <span class="text-danger">*</span></label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.dispositivo === true ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, dispositivo:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.dispositivo === false ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, dispositivo:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Se caso a pergunta anterior for afirmativa, especifique o tipo de dispositivo, como marcapasso , desfibrilador, stent, váula, entre outros)</label>
                    <input type="text" placeholder="Digite a especificação" 
                      onChange={e => {setFormSeguranca({...formSeguranca, dispositivo_especifique: e.target.value})}} className="form-control" 
                      disabled={formSeguranca?.dispositivo === false ? true : false} />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Você possui algum dispositivo de fixação óssea? (Pino, placas metálicas ou parafuso) <span class="text-danger">*</span> </label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.fixacao === true ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, fixacao:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.fixacao === false ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, fixacao:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Caso a pergunta anterior for afirmativa, especifique dispositivo e local.</label>
                    <input type="text" placeholder="Digite a especificação" 
                      onChange={e => {setFormSeguranca({...formSeguranca, fixacao_especifique: e.target.value})}} className="form-control" 
                      disabled={formSeguranca?.fixacao === false ? true : false} />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Você faz uso de alguma prótese móvel? (auditiva, ocular, dentária, ortopédica) <span class="text-danger">*</span> </label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.protese === true ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, protese:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.protese === false ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, protese:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Caso a pergunta anterior for afirmativa, especifique dispositivo e local.</label>
                    <input type="text" placeholder="Digite a especificação" 
                      onChange={e => {setFormSeguranca({...formSeguranca, protese_especifique: e.target.value})}} className="form-control" 
                      disabled={formSeguranca?.protese === false ? true : false} />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Aparelho odontológico fixo? <span class="text-danger">*</span> </label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.aparelho === true ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, aparelho:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.aparelho === false ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, aparelho:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Clipe cirúrgico  em alguma parte do corpo? <span class="text-danger">*</span> </label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.clipe === true ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, clipe:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.clipe === false ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, clipe:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Caso a pergunta anterior for afirmativa, especifique o local.</label>
                    <input type="text" placeholder="Digite a especificação" 
                      onChange={e => {setFormSeguranca({...formSeguranca, clipe_especifique: e.target.value})}} className="form-control" 
                      disabled={formSeguranca?.clipe === false ? true : false} />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Já sofreu ferimento por arma de fogo/projétil? <span class="text-danger">*</span> </label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.projetil === true ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, projetil:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.projetil === false ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, projetil:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Caso a pergunta anterior for afirmativa, especifique em que região do corpo.</label>
                    <input type="text" placeholder="Digite a especificação" 
                      onChange={e => {setFormSeguranca({...formSeguranca, projetil_especifique: e.target.value})}} className="form-control" 
                      disabled={formSeguranca?.projetil === false ? true : false} />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Você tem tatuagem, maquiagem definitiva, micro pigmentação de sobrancelha?</label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.tatuagem === 0 ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, tatuagem:0})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.tatuagem === 1 ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, tatuagem:1})}}/>
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label>A partilha da informação sobre quanto tempo a pratica estética foi realizada, nos possibilitará definir o protocolo apropriado para seu exame. </label>
                    <div className="input-group">
                      <DatePicker
                        name="dt_final"
                        onChange={e => { setFormSeguranca({ ...formSeguranca, tatuagem_data: e }); }}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa"
                        dateFormat="dd/MM/y"
                        selectsEnd
                        disabled={formSeguranca?.tatuagem === 1 || formSeguranca?.tatuagem === 2 ? true : false}
                        selected={formSeguranca.tatuagem_data}
                        customInput={
                          <InputMask mask="99/99/9999" />} />
                      </div>
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Você esta usando atualmente cânula de traqueostomia metálica? <span class="text-danger">*</span> </label>
                    <div class="radio-inline">
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.canula === true ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, canula:true})}} />
                      <span></span>Sim</label>
                      <label class="radio">
                      <input type="radio" checked={formSeguranca?.canula === false ? true : false} 
                        onChange={e=>{ setFormSeguranca({...formSeguranca, canula:false})}} />
                      <span></span>Não</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    
                  </div>
                </div>


                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <div class="row">
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th>Esta em uso de algum destes dispositivos médicos?</th>
                            <th className='text-center'>Sim</th>
                            <th className='text-center'>Não</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Bomba de infusão de medicamento</th>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.bomba_infusao === 0 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, bomba_infusao:0})}} /></td>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.bomba_infusao === 1 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, bomba_infusao:1})}} /></td>
                          </tr>
                          <tr>
                            <th>Monitor PIC (pressão intracraniana)</th>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.monitor_pic === 0 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, monitor_pic:0})}} /></td>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.monitor_pic === 1 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, monitor_pic:1})}}/></td>
                          </tr>
                          <tr>
                            <th>Monitoramento de PAM (pressão média)?</th>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.monitoramento_pam === 0 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, monitoramento_pam:0})}}  /></td>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.monitoramento_pam === 1 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, monitoramento_pam:1})}}  /></td>
                          </tr>
                          <tr>
                            <th>Monitoramento cardíaco</th>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.monitoramento_cardio === 0 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, monitoramento_cardio:0})}}/></td>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.monitoramento_cardio === 1 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, monitoramento_cardio:1})}} /></td>
                          </tr>
                          <tr>
                            <th>Cateter Swan-ganz</th>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.cateter === 0 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, cateter:0})}} /></td>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.cateter === 1 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, cateter:1})}} /></td>
                          </tr>
                          <tr>
                            <th>Respirador</th>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.respirador === 0 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, respirador:0})}} /></td>
                            <td className='text-center'><input type="radio" checked={formSeguranca?.respirador === 1 ? true : false} 
                               onChange={e=>{ setFormSeguranca({...formSeguranca, respirador:1})}} /></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-6">
                    <label>Algum comentário adicional que ache pertinente?</label>
                    <input type="text" value={formSeguranca?.comentario} onChange={e => {setFormSeguranca({...formSeguranca, comentario: e.target.value})}} className="form-control" />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Algum feedback geral?</label>
                    <input type="text" value={formSeguranca?.feedback} onChange={e => {setFormSeguranca({...formSeguranca, feedback: e.target.value})}} className="form-control" />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <label>Responsável pela realização do questionário (Nome completo e grau de parentesco)</label>
                    <input type="text" value={formSeguranca?.resposavel} onChange={e => {setFormSeguranca({...formSeguranca, responsavel: e.target.value})}} className="form-control" />
                  </div>
                </div>

                <div className="row ml-5 mr-5">
                  <div className="form-group col-md-12">
                    <h4 className='text-danger text-center'>Por favor, lembre-se que o aparelho de ressonância  fica dentro de um campo magnético. Desta forma, antes de entrar na sala remova todos os objetos metálicos, caso seja  possível evite  de traze-los ou porta-los no dia  do  exame para melhor otimização do atendimento.  </h4>
                  </div>
                </div>

                <div className='row ml-5 mr-5'>
                  <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold btn-block mb-5"><i className="fas fa-save" />Salvar</button>
                </div>
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>       
                 
        </>
    );
}

export default withRouter(Seguranca);                  